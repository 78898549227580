<template>
    <div class="container">
        <!-- <select v-model="departement" class="form-select mb-3" style="width: 100%;" required v-on:change="changeDept($event)">
            <option class="w3-white" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                {{ option.text }}
            </option>
        </select> -->
        <div class="container pt-1 scrlt-y" v-bind:style="'height:'+  (screenHeight-130) +'px;'">
            <div class="row">
                <div class="row mb-3">
                    <div class="col-md-6 mb-3">
                        <div class="card">
                            <div class="container row m-2 mt-3">
                                <div class="col-md-12">
                                    <p class="h9 fw-bold mb-0">Supplier Online</p>
                                    <p class="h1 fw-bold">{{card.suppon}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-archive-refresh-outline"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="container row m-2 mt-3">
                                <div class="col-md-12">
                                    <p class="h9 fw-bold mb-0">Supplier Offline</p>
                                    <p class="h1 fw-bold">{{card.suppoff}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-archive-refresh-outline"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Fillter Supplier</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Pembelian</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active pt-3" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                    <div class="card">
                        <div class="container">
                            <div class="card-header bg-transparent fw-bold h9 mb-3">
                                Filter
                            </div>
                            <div class="w3-animate-zoom mb-3">
                                <form class="container mb-3" id="frmCust" v-on:submit.prevent="showSupp" autocomplete="off">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="h9 fw-bold">Supplier</p>
                                            <select v-model="recSupp.status" class="form-select" required>
                                                <option value="" disabled selected>Pilih Status</option>
                                                <option value="2">On</option>
                                                <option value="1">Off > 3 Bulan</option>
                                                <option value="0">Off</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="fw-bold h9">Bulan</p>
                                            <select v-model="recSupp.bulan" class="form-select" required>
                                                <option value="" disabled selected>Pilih Bulan</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="fw-bold h9">Tahun</p>
                                            <div class="row">
                                                <div class="col-md-9">
                                                    <input v-model="recSupp.tahun" class="form-control col-md-6" type="number" placeholder="Tahun" required>
                                                </div>
                                            <button type="submit" class="btn btn-primary col-md-3" form="frmCust"><i class="fa fa-search"></i>&nbsp; Show</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="container mt-3">
                                <table class="table table-hover" id="recsupp">
                                    <thead>
                                        <tr class="h9">
                                            <th class="">Supplier ID</th>
                                            <th class="">Supplier Name</th>
                                            <th class="">Alamat</th>
                                            <th class="">Tlp</th>
                                            <th class="">Last Transaction</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                    <div class="col-md-12 mb-3">
                        <div class="card p-3">
                            <div class="card-header bg-transparent mb-3 h9 pt-0 fw-bold">
                                Table Supplier
                            </div>
                            <table class="table table-hover table-responsive" id="ratio" style="cursor:pointer">
                                <thead>
                                    <tr class="table-head">
                                        <th class="">Nama</th>
                                        <th class="">{{yearSub}}</th>
                                        <th class="">{{year}}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
              </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import PieChart from "../chart/PieChart.js";
import moment from "moment";

// import "jquery/dist/jquery.min.js";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'D-Supplier',
    components: {
        PieChart,
        // TableRatio
        // AgGridVue
    },
    data() {
        return {
            sales_name : localStorage.username,
            screenHeight : 0,
            day : moment(new Date()).format('DD'),
            month : moment(new Date()).format('MMM'),
            year : moment(new Date()).format('YYYY'),
            monthSub : moment().subtract(1, 'months').format('MMM YYYY'),
            yearSub : moment().subtract(1, 'year').format('YYYY'),
            card : {
                suppon : 0,
                suppoff : 0,
            },
            rectargetdtl: {},
            recCharts : [],
            chartData: {},
            chartOptions: {},
            chartLabel : [],
            lineData : {},
            lineOptions : {},
            LdtNow : [],
            LdtLast : [],
            LineLabel : [],
            departement : 'SUB',
            dept: [
                { text: 'SURABAYA', value: 'SUB' },
                { text: 'SEMARANG', value: 'SRG' }
            ],
            recsratiocust : [],
            recSupp : {
                status : '',
                bulan : '',
                tahun : ''
            },
            recsSupp : []
        }
    },
    methods : {
        changeDept(event){
            this.LoadData();
        },
        LoadData(){
            let self = this;
            let param = {
                pfunction : 'statussupp',
                filter : 'supp',
                dept: 'ALL'
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.card.suppoff = response.data.rec[0].tc_nilai;
                self.card.suppon = response.data.rec[1].tc_nilai;
            });
        },
        LoadDatas() {
            let self = this;
            function toRp(value) {
                if (!value) return 'Rp 0';
                return 'Rp ' + parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }

            document.getElementById('idLoading').style.display = 'block';

            axios.post("dashboard/ApiDboard.php", {
                pfunction: "ratiosupp",
                dept: 'ALL'
            }).then(function (response) {
                document.getElementById('idLoading').style.display = 'none';
                self.recsratiocust = response.data.rec;

                $('#ratio').DataTable({
                destroy: true,
                responsive: true,
                scrollX: false,
                deferRender: true,
                autoWidth: false,
                data: self.recsratiocust,
                columns: [
                    { "data": "0" },
                    {
                    "data": "1",
                    render: function(data, type, row) {
                        return toRp(data);
                    }
                    },
                    {
                    "data": "2",
                    render: function(data, type, row) {
                        return toRp(data);
                    }
                    }
                ],
                columnDefs: [],
                order: [[1, "desc"]],
                dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                    "<'col-md-6'B>" +
                    "<'row'<'col-md-12 h9 mb-3'tr>>" +
                    "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                buttons: [
                    {
                    extend: 'csvHtml5',
                    text: 'Export',
                    className: 'btn btn-primary',
                    }
                ],
                });
            });
        },
        showSupp(event){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            axios.post("dashboard/ApiDboard.php",{
                pfunction : 'filtersupp',
                status : self.recSupp.status,
                bulan : self.recSupp.bulan,
                tahun : self.recSupp.tahun
            }).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.recsCust = response.data.rec;
                $('#recsupp').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: true,
                    deferRender: true,
                    autoWidth: false,
                    data : self.recsCust,
                    columns: [
                        {"data": "SuppID"},
                        {"data": "SuppName"},
                        {"data": "Address"},
                        {"data": "Phones"},
                        {"data": "lastdt"}
                    ],
                    columnDefs: [
                    ],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                    "<'col-md-6'B>" +
        "<'row'<'col-md-12 h9 mb-3'tr>>" +
        "<'row'<'col-sm-12 col-md-5 h9'i><'col-sm-12 col-md-7'p>>",
                    buttons: [
                        {
                            extend: 'csvHtml5', text: 'Export',
                            className: 'btn btn-primary',
                        }
                    ],
                    // buttons: ['csv']
                });
            });
            event.preventDefault();
            return false;
        },
        setInit() {
            this.LoadData();
            this.LoadDatas();
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>
